import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Card, FilterBar, Hero, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../../styles/main.less";

const ContentContainer = styled.div`
    font-family: effra, sans-serif;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
`;

const ResourcesDiv = styled.div`
    background-color: #e5e5e5;
    padding-bottom: 4rem;
`;

const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const Resources = ({ data }) => {
    const { copy, items, site, stream } = data;

    // Filter items with those in the selected stream
    const filteredItems = items.edges
        .filter((edge) => edge.node.data.streams.includes(stream.recordId))
        .map((item) => item.node);

    // Build Cards
    const cards = filteredItems && filteredItems.map((node) => <Card copyData={copy.data} key={node.id} node={node} />);
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "consumer",
        locale: site.siteMetadata.lang,
        page: "cn-consumer-dynamic-resources"
    });
    config.page.helmet.meta_title = stream.data.seo_title || "";
    config.page.helmet.meta_description = stream.data.seo_description || "";
    config.page.helmet.canonical_url = stream.data.canonical_url || "";

    return (
        <PageTemplateWrapper config={config}>
            <ContentContainer>
                <Hero
                    contactPath={copy.data.contact_ee_url}
                    copyData={copy.data}
                    ctaText={copy.data.request_pricing}
                    domain={site.siteMetadata.legacy.domain}
                    image={<img src={stream.data.hero_image} alt={stream.data.hero_image} />}
                    mainHeading={stream.data.display_name}
                    market={site.siteMetadata.legacy.market}
                    subHeading={stream.data.sub_title}
                    vertical={site.siteMetadata.legacy.vertical}
                />
                <ResourcesDiv>
                    <FilterBar copyData={copy.data} vertical={site.siteMetadata.legacy.vertical} />
                    <CardsContainer>{cards}</CardsContainer>
                </ResourcesDiv>
            </ContentContainer>
        </PageTemplateWrapper>
    );
};

export default Resources;

export const pageQuery = graphql`
    query itemsQuery($rawId: String!) {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        items: allAirtable(
            sort: { fields: data___date_added, order: DESC }
            filter: { table: { eq: "Items" }, data: { market: { eq: "CN" } } }
        ) {
            edges {
                node {
                    ...ItemsFragment
                }
            }
        }
        site {
            ...SiteFragment
        }
        stream: airtable(id: { eq: $rawId }) {
            ...StreamFragment
        }
    }
`;
